import { Box, Checkbox, Typography } from "@mui/material";
import { availableDays } from "./bookingsComponentStyles";
import TimeRangeSelectorComponent from "./TimeRangeSelectorComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { uppercaseFirstLetter } from "../../helperFunctions";

const SettingsAvailableTimesComponent = (props) => {
  const classes = availableDays();
  const { availableData, changeAvailableData, day, setChanged } = props;
  console.log(availableData);
  return (
    <Box className={classes.outer}>
      <Box className={classes.dayBox}>
        <Typography variant="h4">{uppercaseFirstLetter(day)}</Typography>
      </Box>
      <Box className={classes.timeBox}>
        {availableData[day].length > 0 ? (
          <Box>
            {availableData[day].map((slot, i) => (
              <TimeRangeSelectorComponent
                index={i}
                key={i}
                day={day}
                changeAvailableData={changeAvailableData}
                availableData={availableData}
                slot={slot}
                setChanged={setChanged}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color={"#999999"}>
            No slots on this day.
          </Typography>
        )}
      </Box>
      <Box
        className={classes.addBox}
        onClick={() => {
          changeAvailableData({
            ...availableData,
            [day]: [...availableData[day], { from: null, to: null }],
          });
        }}
      >
        <AddCircleOutlineIcon />
        <Typography variant="h4">Add a slot</Typography>
      </Box>
    </Box>
  );
};

export default SettingsAvailableTimesComponent;
