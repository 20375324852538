import { Avatar, Box, MenuItem, Select, Typography } from "@mui/material";
import { questionsSettingsLayoutStyles } from "../../pages/pagesStyles";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CreateIcon from "@mui/icons-material/Create";
import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getSettings, updateSettings } from "../../apiHelper";
import OnboardingContext from "./OnboardingContext";

const ChooseQuestions = () => {
  const classes = questionsSettingsLayoutStyles();
  const [questionType, setQuestionType] = useState("random");
  const [questionList, setQuestionList] = useState("prescribed");
  const { user, getAccessTokenSilently } = useAuth0();
  const [settings, setSettings] = useState([]);
  const { setNextFunction } = useContext(OnboardingContext);

  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getSettings(t).then((s) => {
        setSettings(s);
      });
    });
  }, []);

  const triggerChangeSettings = (settingsData) => {
    getAccessTokenSilently().then((t) => {
      updateSettings(
        t,
        settings.data && settings.data.length > 0 ? settings.data[0]._id : "",
        { type: questionType }
      );
    });
  };

  useEffect(() => {
    setNextFunction(() => triggerChangeSettings);
  }, [questionType, questionList, settings]);

  return (
    <Box>
      <Box className={classes.selectContainerOuter}>
        <Typography variant="h2">How should questions be asked?</Typography>
        <Box className={classes.selectContainer}>
          <Select
            variant="standard"
            onChange={(e) => {
              setQuestionType(e.target.value);
            }}
            value={questionType}
            disableUnderline={true}
            MenuProps={{ disablePortal: true }}
          >
            <MenuItem value="random">
              <Box className={classes.selectOptionInner}>
                <Avatar classes={{ root: classes.iconContainerAvatar }}>
                  <ShuffleIcon sx={{ fontSize: "1rem" }} />
                </Avatar>

                <Typography variant="body1">Randomise questions</Typography>
              </Box>
            </MenuItem>
            <MenuItem value="set">
              <Box className={classes.selectOptionInner}>
                <Avatar classes={{ root: classes.iconContainerAvatar }}>
                  <ViewWeekIcon sx={{ fontSize: "1rem" }} />
                </Avatar>
                <Typography variant="body1">
                  Set question for each day of the week
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box className={classes.selectContainerOuter}>
        <Typography variant="h2">What questions do you want to use?</Typography>
        <Box className={classes.selectContainer}>
          <Select
            variant="standard"
            onChange={(e) => {
              setQuestionList(e.target.value);
            }}
            value={questionList}
            disableUnderline={true}
            MenuProps={{ disablePortal: true }}
          >
            <MenuItem value="own">
              <Box className={classes.selectOptionInner}>
                <Avatar classes={{ root: classes.iconContainerAvatar }}>
                  <CreateIcon sx={{ fontSize: "1rem" }} />
                </Avatar>

                <Typography variant="body1">
                  I will add my own questions
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem value="prescribed">
              <Box className={classes.selectOptionInner}>
                <Avatar classes={{ root: classes.iconContainerAvatar }}>
                  <LocalLibraryIcon sx={{ fontSize: "1rem" }} />
                </Avatar>
                <Typography variant="body1">
                  I would like to use Ardorio's curated questions
                </Typography>
              </Box>
            </MenuItem>
          </Select>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseQuestions;
