import { helpContainerComponentStyles } from "./componentsStyles";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box, Typography } from "@mui/material";
import AvailableTimesComponent from "./AvailableTimesComponent";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { convertToDayJsWeekNumber } from "../helperFunctions";
import UnavailableComponent from "./UnavailableComponent";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const HelpDateComponent = (props) => {
  const classes = helpContainerComponentStyles();
  const {
    bookingData,
    changeBookingData,
    suggest,
    availableData,
    selectedUserIndex,
    setSelectedUserIndex,
    maxIndex,
  } = props;
  const [selectedDayOfWeek, changeSelectedDayOfWeek] = useState();

  const [availableSlots, changeAvailableSlots] = useState([]);
  const [selectedSlot, changeSelectedSlot] = useState(null);
  console.log(availableData);
  useEffect(() => {
    changeAvailableSlots([]);
    if (selectedDayOfWeek) {
      let availableSlots = availableData.allSlots?.[selectedDayOfWeek];
      availableData.bookedSlots?.forEach((slot) => {
        if (
          dayjs(bookingData.bookingDate).isSame(dayjs(slot.bookingDate), "day")
        ) {
          availableSlots = availableSlots.filter(
            (as) => as !== slot.bookingTime
          );
        }
      });
      changeAvailableSlots(availableSlots);
    }
  }, [selectedDayOfWeek]);
  return (
    <Box className={classes.helpDateContainer}>
      {suggest ? (
        ""
      ) : (
        <Typography variant="h2">
          Book a session to discuss how we can help best
        </Typography>
      )}
      <Box className={classes.personSelectorContainer}>
        <KeyboardArrowLeftIcon
          className={
            selectedUserIndex !== 0
              ? classes.arrowLeft
              : classes.arrowLeftDisabled
          }
          onClick={() => {
            if (selectedUserIndex !== 0) {
              setSelectedUserIndex(selectedUserIndex - 1);
            }
          }}
        />
        <Typography variant="h5">
          Anonymous Person {selectedUserIndex + 1}
        </Typography>
        <KeyboardArrowRightIcon
          className={
            selectedUserIndex + 1 < maxIndex
              ? classes.arrowRight
              : classes.arrowRightDisabled
          }
          onClick={() => {
            if (selectedUserIndex + 1 < maxIndex) {
              setSelectedUserIndex(selectedUserIndex + 1);
            }
          }}
        />
      </Box>
      <Box className={classes.helpDateInner}>
        <StaticDatePicker
          onChange={(d) => {
            changeSelectedSlot(null);
            changeBookingData({
              ...bookingData,
              bookingDate: d,
            });
            changeSelectedDayOfWeek(convertToDayJsWeekNumber(dayjs(d).day()));
          }}
          shouldDisableDate={(day) =>
            !availableData.active?.includes(
              convertToDayJsWeekNumber(dayjs(day).day())
            )
          }
          componentsProps={{
            actionBar: {
              actions: [],
            },
          }}
        />
        <Box className={classes.availableDatesContainer}>
          <Typography>Available Times</Typography>
          <Box className={classes.availableDatesListContainer}>
            {availableSlots?.length > 0 ? (
              availableSlots.map((s, i) => {
                return (
                  <AvailableTimesComponent
                    index={i}
                    time={s}
                    selectedSlot={selectedSlot}
                    changeSelectedSlot={changeSelectedSlot}
                    bookingData={bookingData}
                    changeBookingData={changeBookingData}
                  />
                );
              })
            ) : (
              <UnavailableComponent selectedDayOfWeek={selectedDayOfWeek} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpDateComponent;
