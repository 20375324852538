import { createContext, useState } from "react";

const OnboardingContext = createContext();

export const OnboardingContextProvider = ({ children }) => {
  const steps = [
    "Add users",
    "Select Providers",
    "Pick available times",
    "Choose questions",
  ];
  const [nextFunction, setNextFunction] = useState(() => () => {});
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  return (
    <OnboardingContext.Provider
      value={{
        nextFunction,
        setNextFunction,
        activeStep,
        setActiveStep,
        skipped,
        setSkipped,
        steps,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
