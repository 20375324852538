import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";

const UnavailableComponent = (props) => {
  const classes = helpContainerComponentStyles();
  const { selectedDayOfWeek } = props;
  return (
    <Box className={classes.appointment}>
      <Typography variant="body1">
        {selectedDayOfWeek ? "No times available on this day." : "Select a day"}
      </Typography>
    </Box>
  );
};

export default UnavailableComponent;
