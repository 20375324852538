import { Box, Button } from "@mui/material";
import { useContext } from "react";
import OnboardingContext from "./OnboardingContext";
import { useNavigate } from "react-router-dom";

const OnboardingActionButtons = () => {
  const {
    activeStep,
    setActiveStep,
    skipped,
    setSkipped,
    steps,
    nextFunction,
  } = useContext(OnboardingContext);
  const history = useNavigate();
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    nextFunction();
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  return (
    <Box>
      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
        Add Later
      </Button>
      <Button
        onClick={() => {
          handleNext();
          if (activeStep === steps.length - 1) {
            history("/dashboard");
          }
        }}
      >
        {activeStep === steps.length - 1 ? "Finish" : "Next"}
      </Button>
    </Box>
  );
};

export default OnboardingActionButtons;
