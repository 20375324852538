import "./App.css";
import ScoreCard from "./pages/ScoreCard";
import Help from "./pages/Help";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HrOverview from "./pages/HrOverview";
import Dashboard from "./pages/Dashboard";
import Questions from "./pages/Questions";
import People from "./pages/People";
import Profile from "./pages/Profile";
import Person from "./pages/Person";
import Bookings from "./pages/Bookings";
import Success from "./pages/Success";
import NotYetSetup from "./pages/NotYetSetup";
import SomethingElse from "./pages/SomethingElse";
import CommentSuccess from "./pages/CommentSuccess";
import Onboarding from "./pages/Onboarding";
import { OnboardingContextProvider } from "./hrDashboardComponents/onboardingComponents/OnboardingContext";

const App = (props) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  if (!isLoading) {
    if (isAuthenticated) {
      return (
        <div className="App">
          <Routes>
            <Route index element={<ScoreCard />} />
            <Route path="help" element={<Help />} />
            <Route path="success" element={<Success />} />
            <Route path="comment-success" element={<CommentSuccess />} />
            <Route path="something-else" element={<SomethingElse />} />
            <Route path="not-yet-setup" element={<NotYetSetup />} />
            <Route
              path="onboarding"
              element={
                <OnboardingContextProvider>
                  <Onboarding />
                </OnboardingContextProvider>
              }
            />
            {/* <Route path="demo" element={<Demo />} /> */}
            <Route path="dashboard" element={<HrOverview />}>
              <Route index element={<Dashboard />} />
              <Route path="people" element={<People />} />
              <Route path="people/:personId" element={<Person />} />
              <Route path="questions" element={<Questions />} />
              <Route path="profile" element={<Profile />} />
              <Route path="bookings" element={<Bookings />} />
            </Route>
          </Routes>
        </div>
      );
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      });
    }
  }
};

export default App;
