import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { addPerson } from "../../apiHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NewUserRow = (props) => {
  const { isHeader } = props;
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "staff",
  });
  const [added, setAdded] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  return (
    <Box sx={{ display: "flex" }}>
      {" "}
      <Box sx={{ margin: "0.5rem" }}>
        {isHeader ? <Typography>First Name</Typography> : null}
        <TextField
          hiddenLabel
          placeholder="John"
          variant="outlined"
          onChange={(e) => {
            setUserData({ ...userData, firstName: e.target.value });
          }}
          error={triggered && !userData.firstName}
        />
      </Box>
      <Box sx={{ margin: "0.5rem" }}>
        {isHeader ? <Typography>Last Name</Typography> : null}
        <TextField
          hiddenLabel
          placeholder="Smith"
          variant="outlined"
          onChange={(e) => {
            setUserData({ ...userData, lastName: e.target.value });
          }}
          error={triggered && !userData.lastName}
        />
      </Box>
      <Box sx={{ margin: "0.5rem", width: 350 }}>
        {isHeader ? <Typography>Email</Typography> : null}
        <TextField
          hiddenLabel
          fullWidth
          placeholder="john@smith.com"
          variant="outlined"
          onChange={(e) => {
            setUserData({ ...userData, email: e.target.value });
          }}
          error={triggered && !userData.email}
        />
      </Box>
      <Box
        sx={
          isHeader
            ? { padding: "1.4375em 0rem", marginTop: "2em", minWidth: 64 }
            : { display: "flex", minWidth: 64 }
        }
      >
        {added ? (
          <CheckCircleIcon color="primary" />
        ) : (
          <Button
            onClick={() => {
              setTriggered(true);
              if (userData.email && userData.firstName && userData.lastName) {
                getAccessTokenSilently().then((t) => {
                  addPerson(t, { ...userData, requestingUserId: user.sub })
                    .then(setAdded(true))
                    .catch((e) => {
                      setAdded(false);
                      console.log(e);
                    });
                });
              }
            }}
          >
            Add
          </Button>
        )}
      </Box>
    </Box>
  );
};

const AddUsers = () => {
  const [rowsToDisplay, setRowsToDisplay] = useState(0);

  return (
    <Box>
      <Typography variant="h1">Add users</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "2rem",
        }}
      >
        <NewUserRow isHeader />
        {Array.from({ length: rowsToDisplay }).map((_item, index) => (
          <NewUserRow index={index} />
        ))}
      </Box>
      <Button
        onClick={() => {
          setRowsToDisplay(rowsToDisplay + 1);
        }}
      >
        + Add Another User
      </Button>
    </Box>
  );
};

export default AddUsers;
