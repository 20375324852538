import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { successPageStyles } from "./pagesStyles";
import { useContext } from "react";
import AddUsers from "../hrDashboardComponents/onboardingComponents/AddUsers";
import OnboardingAvailableTimes from "../hrDashboardComponents/onboardingComponents/OnboardingAvailableTimes";
import OnboardingActionButtons from "../hrDashboardComponents/onboardingComponents/OnboardingActionButtons";
import OnboardingContext from "../hrDashboardComponents/onboardingComponents/OnboardingContext";
import ChooseQuestions from "../hrDashboardComponents/onboardingComponents/ChooseQuestions";

const content = {
  0: <AddUsers />,
  1: <Box />,
  2: <OnboardingAvailableTimes />,
  3: <ChooseQuestions />,
};
const Onboarding = () => {
  const classes = successPageStyles();
  const { activeStep, steps } = useContext(OnboardingContext);
  return (
    <Box className={classes.backdrop}>
      <Box className={classes.outerContainer}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label} index={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ marginTop: "4rem", marginBottom: "4rem" }}>
          {content[activeStep]}
        </Box>
        <OnboardingActionButtons />
      </Box>
    </Box>
  );
};

export default Onboarding;
